const Config = {

    globalUrl: process.env.REACT_APP_API_ENDPOINT,

    version: 'V' + require('../../package.json')['version'],
    build:  require('../../package.json')['BuildVersion'],
};

Object.freeze(Config);
export default Config;
