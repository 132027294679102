import React, { useState, useRef, useEffect } from "react";
import { authenticationService } from "../../services/AuthinticationService";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/input/TextInput";
import SVGraphics from "../../assets/SVGraphics";
import utils from "../../utils/utils";
import { FormBuilder } from "../../components/forms/FormBuilder";
import { Error } from "../../components/forms/Error";
import formData from "../../components/forms/formsData.json";
import AlertConfirm from "../../components/modals/Confirm";
import ReCAPTCHA from "react-google-recaptcha";

export function ForgotPassword() {
    const [forgotPasswordInput, setForgotPasswordInput] = useState({
        Email: "",
    });
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [formType, setFormType] = useState(formData['forgotPassword'])
    const [isLoading, setIsLoading] = useState(true);
    const [BackendErrors, setBackendErrors] = useState(null);
    const [BackendLabel, setBackendLabel] = useState(null);

    const inputClassName = "bg-white rounded-lg  h-[3rem] w-[23rem] bg-transparent font-Poppins text-lg shadow-lg "
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    useEffect(() => {
        // Trigger reCAPTCHA verification when the component mounts
        recaptchaRef.current.execute();
    }, []);

    const SubmitFunc = async () => {
        if (recaptchaValue) {
            let response = await authenticationService.forgetPass(forgotPasswordInput, recaptchaValue)
            if (response && response.status < 400) {
                sessionStorage.setItem('userID', response.UserStorageId);
                sessionStorage.setItem('status', response.status);
                //
                let confirm = await AlertConfirm({
                    proceedLabel: 'ok',
                    cancelLabel: 'none',
                    options: {
                        hasSVG: true,
                        svgName: "greenOk",
                        titleColorBlack: true,
                        alertSize: 'w-[24.375rem] h-[13.625rem]'
                    },

                }, "Use your new password to log in. ", `Email Sent!`)

                if (confirm) {
                    navigate("/login", {});
                }
            }
        } else {
            console.error('Please complete reCAPTCHA.');
        }
    }

    const handleChange = async (event) => {
        let inputs = { ...forgotPasswordInput }
        let name = event.target.name
        let value = event.target.value
        forgotPasswordInput[name] = event.target.value
        switch (name) {

            case 'Email':
                if (value && !utils.mailValidation(value)) {
                    setError({ Email: true })
                    setIsError(true)
                    formType[0]['error'] = "Please enter valid email address"
                } else if (!value) {
                    formType[0]['error'] = "Please enter email address"
                    setError({ Email: true })
                    setIsError(true)
                } else {
                    setError({ Email: false })
                    setIsError(false)
                    formType[0]['error'] = ""
                }
                break;
            default:
                setIsError(false)
        }
        inputs[name] = value
        setForgotPasswordInput(inputs)
        setBackendErrors(null)
    }

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const recaptchaRef = React.createRef();


    return (

        <div
            className={"flex-col font-Poppins w-[19.2%] rounded-3xl flex pt-[6rem] mr-auto ml-auto inset-0"}>

            <div>
                <div className={"mt-[2.813rem]  flex-col flex"}>
                    <TextInput text={"Forgot password?"}
                        className={"text-[#FFFFFF] font-Poppins text-3xl font-normal leading-tight"} />
                    <TextInput text={"Please enter the email associated with your account"}
                        className={" mt-[2.281rem] text-[#999996] leading-trim font-Poppins text-base font-normal leading-normal"} />
                </div>


                <div className={"w-[23rem] h-[1px] bg-[#EFEFEF] mt-[0.531rem]"} />
                <div className={"mt-[2.25rem]"}>
                    <FormBuilder
                        data={formType}
                        formData={forgotPasswordInput}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"flex-col flex w-full h-full"}
                        labelClassName={"text-black leading-trim font-Poppins text-lg font-normal leading-7"}
                        inputClassName={inputClassName}
                        isShown={error}
                        isError={isError}
                    />
                </div>

            </div>

            <div className={"w-full text-left mt-2 ml-24"}>
                <Error
                    message={BackendErrors}
                    isShown={BackendErrors} />
            </div>

            {BackendLabel && <div className={"w-full text-left mt-2 ml-24"}>
                <TextInput text={BackendLabel}
                    className={" mt-[0.313rem] text-[#999996] leading-trim font-Poppins text-base font-normal leading-normal"} />

            </div>}


            <div className={'mt-[9.813rem]'}>
                <button type="button"
                    onClick={() => SubmitFunc()}
                    disabled={!forgotPasswordInput['Email'] || (forgotPasswordInput['Email'] && !utils.mailValidation(forgotPasswordInput['Email']))}

                    className="rounded-[3.75rem] bg-[#52007E] shadow-md flex w-[23rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-white
                            font-Poppins text-lg font-bold leading-[1.688rem] capitalize">
                    Ok
                </button>
            </div>
            <div
                className={"ml-auto mt-[0.75rem] leading-trim text-edge-cap font-Poppins text-base leading-55 underline capitalize text-[#FFFFFF]"}
                onClick={() => navigate('/login')}>
                Back to login
            </div>

            <div className={'hidden'}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                    size="invisible"
                />
            </div>
        </div>


    )
}

