import axios from "axios";
import { authenticationService } from "../AuthinticationService";
import restApi from "./RestApi";

const globalUrl = process.env.REACT_APP_API_ENDPOINT
const getCurrentUser = () => {
    return authenticationService.currentUserValue;
}

const userApiService = {

    getAllDeviceSessions: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/${index}/sessions`
        );
    },

    getDeviceSession: async (index, sessionIndex) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/${index}/session/${sessionIndex}`
        );
    },
    getDeviceData: async (filters) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/dashboard/device_data`,
            filters
        );
    },

    get_watch_xlsx: async (filters) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/dashboard/get_watch_xlsx`,
            filters
        );
    },

    getDeviceSessionData: async (sessionIndex) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/getFullSessionDataBySessionId/${sessionIndex}`
        );
    },

    getRecording: async (recordingPath) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/device/getRecording`,
            { recordingPath }
        );
    },

    getSessionsStatistics: async (body) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/dashboard/getSessionsStatistics`,
            body
        );
    },


    getDeviceSessionData: async (sessionIndex) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/getFullSessionDataBySessionId/${sessionIndex}`
        );
    },
};

export default userApiService
