import React, { useEffect, useState } from "react";
import './App.css';
import { Route, Routes, useNavigate } from "react-router-dom";
import { authenticationService } from "./services/AuthinticationService";
import { AuthApiService } from "./services/ApiService";
import IdleTimer from "react-idle-timer";
import { Login } from "./views/auth/Login";
import Config from "./config/Config";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SuperAdminApiService from "./services/ApiServices/SuperAdminApiService";
import AdminApiService from "./services/ApiServices/AdminApiService";
import { MainDashboard } from "./views/app/MainDashboard";
import { SideNavigationBar } from "./components/navigation/SideNavigationBar";
import { UserComps } from "./views/app/UserComps";
import AddNewUser from "./views/app/users/AddNewUser";
import EditUser from "./views/app/users/EditUser";
import { ResetPassword } from "./views/auth/ResetPassword";
import { ForgotPassword } from "./views/auth/ForgotPassword";
import { VerificationCode } from "./views/auth/VerificationCode";
import { DeviceMainDashboard } from "./views/app/dashboard/DeviceMainDashboard";
import { SessionInfo } from "./views/app/dashboard/SessionInfo";
const logo = require('./assets/logo1.png')
function App() {
  const [isIdle, setIsIdle] = useState(false);
  const autoLogoutTime = 3600000 * 3; // 1 hour in milliseconds
  const navigate = useNavigate();
  const [beVersion, setBeVersion] = useState('');
  const pathname = window.location.pathname
  if (authenticationService.isLoggedin) {
  }

  // console.log("authenticationService.isLoggedin()", authenticationService.isLoggedin())
  const currentUser = authenticationService.isLoggedin() && authenticationService.currentUserValue ? authenticationService.currentUserValue.role : ""
  const isLoginRoutes = pathname === '/login' || pathname === '/' || pathname === '/verificationCode' || pathname === '/forgotPassword' || pathname === '/ResetPassword'
  const rolePath = currentUser ? currentUser : ""

  useEffect(() => {
    if (!isLoginRoutes && !currentUser) {
      navigate("/login")
    }

    const currentUrl = window.location.href;
    let currentUserRole = currentUser ? currentUser : ""
    if (!currentUrl.includes(currentUserRole)) {
      navigate(`${rolePath}/Dashboard`)
    }

    const getVersion = async () => {
      let BEVersion = await AuthApiService.getVersion()
      setBeVersion(BEVersion.data)
    }
    getVersion().then()

  }, [])

  const handleLogout = () => {
    authenticationService.logout();
    navigate("/login");
  };

  const handleIdle = () => {
    setIsIdle(true);
    handleLogout();
  };



  const getUserMenuOptions = (user) => {
    if (user === "SuperAdmin") {
      return [
        { name: 'Dashboard', label: 'Dashboard', selectedSvg: "dashboardSelected", svg: "dashboard" },
        { name: 'Users', label: 'Users', selectedSvg: "profileAdminSelected", svg: "profileAdmin" },
      ]
    } else if (user === "Admin") {
      return [
        { name: 'Dashboard', label: 'Dashboard', selectedSvg: "dashboardSelected", svg: "dashboard" },
      ]
    } else {
      return []
    }
  }

  const getUserRoutes = (user) => {
    let restApi = ""
    if (user === "SuperAdmin") {
      restApi = SuperAdminApiService
      return [
        { path: `/${rolePath}/Dashboard`, element: <MainDashboard restApi={restApi} rolePath={rolePath} /> },
        { path: `/${rolePath}/Device/:watchIndex`, element: <DeviceMainDashboard restApi={restApi} rolePath={rolePath} /> },
        { path: `/${rolePath}/Users`, element: <UserComps restApi={restApi} rolePath={rolePath} /> },
        { path: `/${rolePath}/Users/AddUser`, element: <AddNewUser restApi={restApi} rolePath={rolePath} /> },
        { path: `/${rolePath}/Users/EditUser/:UserIndex`, element: <EditUser restApi={restApi} rolePath={rolePath} /> },
        // {path: `/${rolePath}/Companies`, element: <CompaniesComp restApi={restApi} rolePath={rolePath}/>},
      ]

    }
    if (user === "Admin") {
      restApi = AdminApiService
      return [
        { path: `/${rolePath}/Dashboard`, element: <MainDashboard restApi={restApi} rolePath={rolePath} /> },
        { path: `/${rolePath}/Device/:watchIndex`, element: <DeviceMainDashboard restApi={restApi} rolePath={rolePath} /> },
        // {path: `/${rolePath}/Companies`, element: <CompaniesComp restApi={restApi} rolePath={rolePath}/>},
      ]

    } else {
      return []
    }

  }

  const userRoutes = getUserRoutes(currentUser)

  const NavTypes = getUserMenuOptions(currentUser)

  const marginTop = window.innerHeight - 703
  return (

    <div className={`w-full h-screen bg-gradient-to-b from-[#000000] to-[#1A092C]`}>


      <div className={`w-full h-screen ${!isLoginRoutes ? 'flex' : ''}`}>
        {!isLoginRoutes && currentUser ?
          <div className={'w-[20rem]'}>
            <IdleTimer
              onIdle={handleIdle}
              timeout={autoLogoutTime}
              events={['keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove']}
            />
            <img src={logo} className={" pt-[1rem]  flex w-[3rem] ml-[5rem] self-center"} />

            <SideNavigationBar
              logoutUser={() => handleLogout()}
              rolePath={rolePath}
              NavTypes={NavTypes} />
          </div>
          : null
        }

        <Routes>
          {currentUser ?
            userRoutes.map(route => {
              return <Route key={route.path} path={route.path} element={route.element} />
            })
            : null}

          <Route exact path="/verificationCode" element={<VerificationCode />} />
          {/* <Route exact path="/forgotPassword" element={<ForgotPassword/>}/> */}

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotPassword" element={<ForgotPassword />} />
          <Route exact path="/ResetPassword" element={<ResetPassword />} />
          <Route exact path="/" element={<Login />} />
        </Routes>


        {isLoginRoutes && <OverlayTrigger
          placement={"top"}
          delay={{ show: 250, hide: 750 }}
          trigger={["hover", "focus"]}
          // show={true}
          rootClose
          overlay={
            <Tooltip id="tooltip-disabled" className={"versionToolTip"}>
              <label
                className={'text-[#FFFFFF] text-sm'}>be-V:{beVersion?.version} </label>
            </Tooltip>}>
          <div
            className={`flex w-[3.5%] text-[#FFFFFF] text-xs ml-auto 2xl:mt-[100px] lg:mt-[300px]`}>{Config.version} ({Config.build})

          </div>

        </OverlayTrigger>}
      </div>




    </div>
  );
}

export default App;
