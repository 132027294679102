import ExcelJS from 'exceljs';


export async function generateExcelAndDownload(data, filename) {
    const ExcelJS = require('exceljs');

    // Create a workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add headers based on keys of the first object in data array
    if (data.length > 0) {
        const headers = Object.keys(data[0]);
        worksheet.columns = headers.map(header => ({
            header,
            key: header,
            width: 15
        }));
    }

    // Add rows using the data
    data.forEach(row => {
        worksheet.addRow(row);
    });

    // Convert workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert buffer to blob
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element to trigger download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename || 'excel.xlsx';
    link.click();
}
