import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "../../../components/table/table";
import BodyHeader from "../../../components/customComp/BodyHeader";
import { HeaderInfo } from "./HeaderInfo";
import { SessionInfo } from "./SessionInfo";
import { DeviceSessionsInfo } from "./DeviceSessionsInfo";
import { DeviceStatisticsInfo } from "./DeviceStatisticsInfo";
import { generateExcelAndDownload } from "../../../services/functions/createXlsx";
import SVGraphics from "../../../assets/SVGraphics";
import { LoadingComponent } from "../../../components/input/LoadingComponent";


export function DeviceMainDashboard(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [currentView, setCurrentView] = useState(1.1);
    const [currentSession, setCurrentSession] = useState({});
    const [entry, setEntry] = useState({});
    const [sessions, setSessions] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        fetchData().then(data => {
            let response = data ? data.data : {}
            let sessions = response['patientSessions'] ? response['patientSessions'] : []
            setEntry({ ...response })
            setSessions([...sessions])
            setIsLoading(false)

        })

    }, [])
    const fetchData = async () => {
        let index = params.watchIndex
        return await props.restApi.getAllDeviceSessions(index)
    }

    const changeView = async (viewNum, sessionIndex) => {
        if (sessionIndex) {
            setIsLoading(true)
            const { data } = await props.restApi.getDeviceSessionData(sessionIndex)
            setSessionData(data)
            let findSession = sessions.findIndex(item => item['patientSessionIndex'] === sessionIndex)
            let sessionObj = findSession >= 0 ? sessions[findSession] : {}
            setCurrentSession(sessionObj)
            setIsLoading(false)

        }
        setCurrentView(viewNum)
    }

    async function exportCSV() {
        await generateExcelAndDownload(sessions, `${params.watchIndex}-sessions.xlsx`)
    }



    return (
        <div className={'w-full px-[5rem] py-[3rem] text-[#FFFFFF]'}>
            <BodyHeader includeButton={currentView !== 2} OnButtonClick={exportCSV} buttonText={"Export to csv"} title={""} includeBackButton={true} Button={false}
                onBackButtonClick={() => navigate(`/${props.rolePath}/Dashboard`)} />

            <div className="h-fit  font-Poppins rounded-lg ">

                <HeaderInfo entry={entry} />

                <div className={"flex mt-[1.5rem]"}>
                    {currentView === 2 ?
                        <>
                            <div className={"cursor-pointer text-[#FFFFFF] text-cap font-Poppins text-[1.5rem] leading[3.75rem] font-bold"}
                                onClick={() => changeView(1.1)}>
                                {"<"} Back to all sessions
                            </div>
                            <div
                                onClick={async () => {
                                    await generateExcelAndDownload(sessionData[0].sessionsData, `${sessionData[0].patientSessionIndex}-currentSession.xlsx`)
                                }}
                                className={"flex justify-center cursor-pointer ml-auto w-[17rem] h-[3rem] py-[0.75rem] px-[1.5rem] text-center text-lg font-bold font-Poppins flex-shrink-0 rounded-full bg-[#52007E] shadow-black " +
                                    "text-[#FFF]"}>
                                {props.includePlus &&
                                    <SVGraphics className={'mt-[0.3rem] w-[1rem] h-[1rem] cursor-pointer flex-shrink-0'} fill="#FEFEFE"
                                        svgname={'plusIcon'} />
                                }
                                <label className={"ml-[12px] cursor-pointer text-center text-lg font-bold font-Poppins" +
                                    "text-[#FFF]"}>export session data</label>
                            </div>
                        </>

                        :
                        <div className={'flex w-[40%] mr-auto ml-auto gap-x-[2rem]'}>
                            <div
                                className={`cursor-pointer w-[40%] border-b border-b-[#7A4794] rounded-md text-center text-xl font-Poppins font-bold ${currentView === 1.1 || currentView === 2 ? ' bg-[#7A4794]' : ''}`}
                                onClick={() => changeView(1.1)}> Info
                            </div>
                            <div
                                className={`cursor-pointer w-[40%] border-b border-b-[#7A4794] rounded-md text-center text-xl font-Poppins font-bold ${currentView === 1.2 ? ' bg-[#7A4794]' : ''}`}
                                onClick={() => { sessions[0]?.patientIndex ? changeView(1.2) : console.log() }}> Statistics
                            </div>
                        </div>}


                </div>

                {currentView === 1.1 &&
                    <DeviceSessionsInfo restApi={props.restApi}
                        changeView={(num, index) => changeView(num, index)} sessions={sessions} />
                }
                {currentView === 1.2 &&
                    <DeviceStatisticsInfo restApi={props.restApi} changeView={(num) => changeView(num)} sessions={sessions} />
                }


                {currentView === 2 && <div className={"mt-[1.5rem]"}>
                    <SessionInfo restApi={props.restApi} sessions={sessions} sessionData={sessionData} isLoading={false} currentSession={currentSession} />
                </div>
                }

            </div>
            {isLoading && <LoadingComponent />}

        </div>
    )
}