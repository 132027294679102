export const filtersNames = [
    {
        name: "Sleep",
        value: "sleep"
    },
    {
        name: "Vibration",
        value: "vibration"

    },
    {
        name: "Percentage of snoring",
        value: "percentageOfSnoring"

    },
    {
        name: "Movements",
        value: "movements",

        subFilter: [{
            name: "flipSide",
            value: 'flipSide'
        },
        {
            name: "slightShift",
            value: 'slightShift'
        }
        ]
    },
    {
        name: "Heart beats",
        value: "hartRate",

        subFilter: [{
            name: "hartRateMax",
            value: 'hartRateMax'
        },
        {
            name: "hartRateAverage",
            value: 'hartRateAverage'
        },
        {
            name: "hartRateMinimum",
            value: 'hartRateMinimum'
        },
        ]
    },
    {
        name: "Breathing pauses",
        value: "breathingPauses"

    },
    {
        name: "Snoring intensity",
        value: "snoring",
        subFilter: [
            {
                name: "snoringMax",
                value: 'snoringMax'
            },
            {
                name: "snoringAverage",
                value: 'snoringAverage'
            },
            {
                name: "snoringMinimum",
                value: 'snoringMinimum'
            },
        ]
    },
    {
        name: "Oxygen saturation",
        value: "oxygenSaturation",
        subFilter: [
            {
                name: "oxygenSaturationMax",
                value: 'oxygenSaturationMax'
            },
            {
                name: "oxygenSaturationAverage",
                value: 'oxygenSaturationAverage'
            },
            {
                name: "oxygenSaturationMinimum",
                value: 'oxygenSaturationMinimum'
            },
        ]
    },
]
