import React from "react"
import SVGraphics from "../../assets/SVGraphics";



export function NoData(props){

    return(

        <div className={"w-full h-80 flex justify-center mt-4 rounded-lg bg-[#7A4794]" }>
            <div className={"flex flex-col justify-center items-center"}>
                <SVGraphics className=" w-16 h-16 flex justify-self-center mb-4" svgname={'files'}/>
                <div className={"font-Rubik text-3xl font-semibold"}>No data to display</div>
                <div className={"font-Rubik font-medium text-base"}>Sorry, Nothing Here</div>
            </div>

        </div>
    )

}