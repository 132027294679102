import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BodyHeader from "../../../components/customComp/BodyHeader";
import { HeaderInfo } from "./HeaderInfo";
import { Table } from "../../../components/table/table";
import SVGraphics from "../../../assets/SVGraphics";
import utils from "../../../utils/utils";
import { SessionChart } from "./SessionChart";

export function SessionInfo(props) {
    const params = useParams();
    const keys = [
        {
            name: "Sleep info", svgName: "sleep", inputs: [
                { name: "BedTime", key: "sessionStartDate", type: "time" },
                { name: "Awaking", key: "sessionEndDate", type: "time" },
                { name: "Sleep time", key: "sleep" }]
        },
        {
            name: "Heart rate", svgName: "heart", inputs: [
                { name: "Minimum heart rate", key: "hartRateMinimum", type: "hartRate" },
                { name: "Maximum heart rate", key: "hartRateMax", type: "hartRate" },
                { name: "Average heart rate", key: "hartRateAverage", type: "hartRate" }
            ]
        },
        {
            name: "Snoring time", svgName: "awake", inputs: [
                { name: "Snoring time", key: "snoringMax", type: "snoring" },
                { name: "Percentage of snoring", key: "percentageOfSnoring" }
            ]
        },
        {
            name: "Snoring noise", svgName: "sleepSnore", inputs: [
                { name: "Snoring DB max", key: "snoringMax", type: "snoring" },
                { name: "Snoring DB average", key: "snoringAverage", type: "snoring" },
                { name: "Snoring DB minimum", key: "snoringMinimum", type: "snoring" },
                // { name: "Percentage of snoring", key: "percentageOfSnoring" }
            ]
        },
        {
            name: "Oxygen saturation", svgName: "breathing", inputs: [
                { name: "Oxygen saturation max", key: "oxygenSaturationMax", type: "oxygenSaturation" },
                { name: "Oxygen saturation average", key: "oxygenSaturationAverage", type: "oxygenSaturation" }]
        },
        {
            name: "respiration", svgName: "breathing", inputs: [
                { name: "respiration Avg", key: "respirationAvg", type: "" },
            ]
        },
        // {
        //     name: "Movements", inputs: [
        //         { name: "Small movements", key: "slightShift", type: "movements" },
        //         { name: "Turning over", key: "flipSide", type: "movements" }]
        // }, if movements will return uncomment it 
        {
            name: "Number of vibrations", svgName: "numberOfVibration", inputs: [
                { name: "Number of vibrations", key: "vibration" }
            ]
        },
    ]

    useEffect(() => {

        fetchData().then(data => {
        })

    }, [])

    function getTimeDifference(fromTime, toTime) {
        const from = new Date(fromTime);
        const to = new Date(toTime);

        const timeDifference = to - from;

        // Convert milliseconds to hours and minutes
        const hours = Math.floor(timeDifference / 1000 / 60 / 60);
        const minutes = Math.round((timeDifference / 1000 / 60) % 60);

        // Format the result
        const formattedDifference = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${(!minutes && !hours ? 1 : minutes)}`;//return the time in hh:mm format. if the time is 00:00 display 00:01

        return formattedDifference;
    }

    const fetchData = async () => {
    }
    return (
        <div className="h-fit  font-Poppins rounded-lg ">

            <div className={`w-full grid grid-cols-4 gap-x-[2rem] gap-y-[1.5rem] lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 sm:grid-cols-3 max-sm:grid-cols-2`}>
                {keys.map((item, index) => {

                    const innerInputs = item['inputs'].map((input, index) => {
                        return <div key={"asdf" + index}>

                            <div>  {input['type'] && input['type'] === 'time' ? utils.getTimeFromDate(props.currentSession[input['key']]) :
                                input['type'] ? props.currentSession[input['type']][input['key']] :
                                    input['key'] === 'percentageOfSnoring' ? props.currentSession[input['key']] :
                                        input['key'] === 'sleep' ?
                                            getTimeDifference(props.currentSession["sessionStartDate"], props.currentSession["sessionEndDate"]) : props.currentSession[input['key']]
                            }
                                {input['key'] === 'percentageOfSnoring' && "%"}
                                <div
                                    className={"font-bold font-Poppins text-sm h-[2rem]"}> {input['name']}</div>

                            </div>

                        </div>
                    })

                    return <div key={item['name']}
                        className={'max-w-[20rem] h-[10rem] w-[20rem] bg-[#191E29] rounded p-[0.8rem]'}>
                        <div className={"flex"} style={{ flexDirection: "column" }}>
                            <div className={"font-bold font-Poppins text-[1rem] h-[1.5rem]"}> {item['name']}</div>
                            <SVGraphics className={"ml-auto w-[1.3rem] h-[1.3rem]"} svgname={item['svgName']} />
                        </div>
                        <div className={"flex gap-x-[2.5rem]"}>
                            {innerInputs}

                        </div>

                    </div>

                })

                }

            </div>

            {/* <SessionChart session={props.sessionData} /> */}

        </div>
    )
}