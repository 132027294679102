import axios from "axios";
import { authenticationService } from "../AuthinticationService";
import Config from "../../config/Config";
import AlertConfirm from "../../components/modals/Confirm";
import { bodyToHash, calcHash } from "./hash";

const globalUrl = Config.globalUrl

class RestApi {
    async get(url) {
        try {
            let authConfig = await this.getAuthConfig(false)
            if (!authConfig) return
            const res = (await axios.get(`${globalUrl}/api/v1/webapp/${url}`, authConfig))
            // if (!url.includes("sessions") && !calcHash(res.config.headers["body-hash"], res.data)) return await AlertConfirm({
            //     proceedLabel: 'Ok',
            //     cancelLabel: 'none',
            //     options: { hasSVG: true, svgName: "alert-triangle" },
            // }, "err", `Error Occur`)
            return res
        } catch (err) {
            let errText = "Unauthorized"
            if (err.response && err.response.data && err.response.data.message) {
                errText = err.response.data.message
            }
            if (err.name !== "AxiosError") {
                return await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: { hasSVG: true, svgName: "alert-triangle" },
                }, errText, `Error Occur`)
            }

        }
    }

    async post(url, data) {
        try {
            let authConfig = await this.getAuthConfig(false)
            if (!authConfig) return
            const hash = bodyToHash(data || {})
            authConfig.headers["body-hash"] = hash
            // const req = axios.create({ method: "POST", url: `${globalUrl}/api/v1/webapp/${url}` }, authConfig)
            // console.log('req:', req)
            const res = (await axios.post(`${globalUrl}/api/v1/webapp/${url}`, data, authConfig,))
            if (!calcHash(res.config.headers["body-hash"], res.data)) return await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: { hasSVG: true, svgName: "alert-triangle" },
            }, "hash error", `Error Occur`)
            return res
        } catch (err) {
            console.log("err", err)
            let errText = "Unauthorized"
            if (err.response && err.response.data && err.response.data.message) {
                errText = err.response.data.message
            }

            if (err.name !== "AxiosError") {
                return await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: { hasSVG: true, svgName: "alert-triangle" },
                }, errText, `Error Occur`)
            } else if (err.response.data.message.includes('3 passwords')) {
                return await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: { hasSVG: true, svgName: "alert-triangle" },
                }, errText, `Password Incorrect`)
            }

        }
    }

    async postFiles(url, data) {
        try {
            let authConfig = await this.getAuthConfig(true)
            return (await axios.post(`${globalUrl}/api/v1/webapp/${url}`, data, authConfig))
        } catch (err) {
            console.log("err", err)
            return err
        }
    }


    async delete(url) {
        try {
            let authConfig = await this.getAuthConfig(false)
            return (await axios.delete(`${globalUrl}/api/v1/webapp/${url}`, authConfig))
        } catch (err) {
            console.log("err", err)
            return err
        }
    }

    async getAuthConfig(isFiles) {
        const currentUser = await authenticationService.currentUserValue;
        if (currentUser)
            if (!authenticationService.checkIfTokenExpired(currentUser.token)) {
                return await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: { hasSVG: true, svgName: "alert-triangle" },
                }, "token Expired ", `Error Occur`)
            }
        const config =
            currentUser && isFiles ? { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': currentUser.token } }
                : currentUser ?
                    { headers: { 'Content-Type': 'application/json', 'Authorization': currentUser.token } }
                    : { headers: { 'Content-Type': 'application/json' } };

        return await new Promise(async (resolve) => {
            let headers = config.headers || {}
            await authenticationService.currentSessionToken().then(async accessToken => {
                let user = JSON.parse(JSON.stringify(authenticationService.currentUserValue));
                if (accessToken !== user['token']) {
                    user['token'] = accessToken

                    // await authenticationService.setUserData(user)
                }
                headers['Authorization'] = accessToken
                config.headers = headers
                resolve(config);
            }).catch(() => {
                config.headers = headers
                resolve(config);
            })
        })

    }
}

let restApi = new RestApi()
export default restApi;
