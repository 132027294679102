import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formData from "../../../components/forms/formsData.json";
import { FormBuilder } from "../../../components/forms/FormBuilder";
import { FormCard } from "../../../components/forms/FormCard";
import { array, object, string } from "yup";
import Constants from "../../../utils/constants";
import BodyHeader from "../../../components/customComp/BodyHeader";
import BodyFooter from "../../../components/customComp/BodyFooter";
import AlertConfirm from "../../../components/modals/Confirm";


const AddNewUser = (props) => {
    const navigate = useNavigate();
    const [entry, setEntry] = useState({});
    const [error, setError] = useState([{}])
    const [isError, setIsError] = useState(false)
    const [usersForm, setUsersForm] = useState(formData['add_user'])

    const validateSchema = object().shape({
        FirstName: string().required('This field is a required field').max(10).min(2).matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        LastName: string().required('This field is a required field').max(20).min(2).matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        Email: string().required('This field is a required field').matches(Constants.emailRegExp, "Invalid Email")
    })



    const validateForm = async (currentEntry) => {
        let value = false
        let formErrors = {}

        await validateSchema.validate(currentEntry, { abortEarly: false })
            .then(function () {
                setIsError(false)
                setError(formErrors)

            }).catch(function (err) {
                err.inner.forEach(error => {
                    formErrors[error.path] = true
                    let findIndex = usersForm.findIndex(x => x.name === error.path)
                    usersForm[findIndex]['error'] = error.errors
                    console.log(error)

                })
                value = true
                setError(formErrors)
                setIsError(value)
            });
        return value
    }


    const onChange = async (name, value) => {
        let user = { ...entry }
        if (value === "") {
            value = null
        }

        user[name] = value
        setEntry({ ...user })

        if (error[name]) {
            await validateForm(user)
        }
    }


    const createForm = async () => {
        let currentEntry = { ...entry }
        let validateEntry = await validateForm(currentEntry)

        if (!validateEntry) {
            let response = await props.restApi.addUser(currentEntry)
            if (response && response.data) {
                navigate(`/${props.rolePath}/Users`)
            }
        } else {
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: { hasSVG: true, svgName: "alert-triangle" },

            }, "Please fill all required fields", ``)
        }
    }



    return (
        <div className="w-full px-[3.75rem] py-[3rem]">
            <BodyHeader title={"New User"} includeButton={false} />

            <FormCard
                cardMargin={''}
                cardTitle={''}
                data={<FormBuilder
                    data={usersForm}
                    marginSecondRow={"mt-[4.125rem]"}
                    marginFirstRow={"mt-[4.125rem]"}
                    formData={entry}
                    hasCardTitle={true}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    containerClassName={"mr-[0.75rem] "}
                    labelClassName={"font-Poppins text-inputSize  font-bold"}
                    inputClassName={"w-[23rem] rounded-lg h-[3rem] font-Poppins "}
                    isShown={error}
                    isError={isError}
                />}
            />


            <BodyFooter cancelText={"Cancel"}
                createText={"Create"}
                marginTop={"mt-[12rem]"}
                showAlert={true}
                onCreateClick={() => createForm()}
                onCancelClick={() => navigate(`/${props.rolePath}/Users`)} />


        </div>
    )
}

export default AddNewUser;
