import React, { useState, useRef, useEffect } from "react";
import { authenticationService } from "../../services/AuthinticationService";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../../components/input/TextInput";
import SVGraphics from "../../assets/SVGraphics";
import { FormBuilder } from "../../components/forms/FormBuilder";
import { Error } from "../../components/forms/Error";
import formData from "../../components/forms/formsData.json";
import AlertConfirm from "../../components/modals/Confirm";

export function ResetPassword() {

    const PasswordDontMatchException = "The passwords do not match"
    const PasswordRequirementException = "The password you entered doesn’t meet the minimum security requirements"

    const [newPasswordInputs, setNewPasswordInputs] = useState({
        newPassword: "",
        confirmNewPassword: ""
    });

    const [isInCorrectCode, setisInCorrectCode] = useState();
    const [errMessage, setErrMessage] = useState();

    const [isError, setIsError] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [formType, setFormType] = useState(formData['newPassword'])
    const [isLoading, setIsLoading] = useState(true);
    const [showPassword, setShowPassword] = useState({ 'newPassword': false, 'confirmNewPassword': false });
    const [BackendErrors, setBackendErrors] = useState(null);
    const [errRegexObj, setErrRegexObj] = useState({})
    const inputClassName = "bg-white rounded-lg  h-[3rem] w-[23rem] bg-transparent font-Poppins text-lg shadow-lg "

    let recaptchaRef = useRef(null)

    const SubmitFunc = async () => {
        let userID = sessionStorage.getItem("userID")
        let status = sessionStorage.getItem("status")
        if (Object.keys(errRegexObj).length > 0) {
            setIsError(PasswordRequirementException)
        } else if (newPasswordInputs.newPassword !== newPasswordInputs.confirmNewPassword) {
            setIsError(PasswordDontMatchException)
        } else {

            setIsLoading(true)
            let response = await authenticationService.changeNewPassword(newPasswordInputs, userID)
            if (response && response.status < 400) {
                let data = response.data
                if (data && data.hasOwnProperty('status') && String(data['status']).toLowerCase() === 'pass') {
                    let confirm = await AlertConfirm({
                        proceedLabel: 'ok',
                        cancelLabel: 'none',
                        options: { hasSVG: true, svgName: "greenOk", titleColorBlack: true, alertSize: 'w-[24.375rem] h-[13.625rem]' },

                    }, "Use your new password to log in. ", `Password changed!`)
                    navigate("/login", {});

                }
            }
            setIsLoading(false)
        }
    }
    const passwordValidation = (password) => {
        let lengthPattern = /^.{8,20}$/;
        let specialCharacterPattern = /^(?=.*[!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?\/]).*$/;
        let oneNumberPattern = /^(?=.*\d).*$/;
        let upperCasePattern = /^(?=.*[A-Z]).*$/;
        let lowerCasePattern = /^(?=.*[a-z]).*$/;

        let errObj = {}

        if (!lengthPattern.test(password)) {
            errObj['1'] = true
        }
        if (!specialCharacterPattern.test(password)) {
            errObj['2'] = true
        }
        if (!oneNumberPattern.test(password)) {
            errObj['3'] = true
        }
        if (!upperCasePattern.test(password)) {
            errObj['4'] = true
        }
        if (!lowerCasePattern.test(password)) {
            errObj['5'] = true
        }
        const isValidationError = Object.keys(errObj).length > 0
        setIsError(null)
        setErrRegexObj(errObj)
    }
    const handleChange = async (event) => {
        let inputs = { ...newPasswordInputs }
        let name = event.target.name
        let value = event.target.value
        inputs[name] = event.target.value
        let form = [...formType]
        let formErrors = { ...error }
        switch (name) {
            case 'newPassword':
                passwordValidation(value)
                if (isError) {
                    formErrors['newPassword'] = true
                } else if (!value) {
                    form[0]['error'] = "Please enter password"
                    formErrors['newPassword'] = true
                    setIsError(true)
                } else {
                    formErrors['newPassword'] = false
                    // setIsError(false)
                    form[0]['error'] = ""
                }
                break;
            default:
                value = event.target.value
        }
        inputs[name] = value
        setNewPasswordInputs(inputs)
        setBackendErrors(null)
        setFormType(form)
        setError(formErrors)
    }
    const setShowPasswordType = (name) => {
        const showPasswordObj = Object.assign({}, showPassword)
        showPasswordObj[name] = !showPassword[name]
        setShowPassword(showPasswordObj)

    }
    return (

        <div
            className={"flex-col font-Poppins w-[19.2%] rounded-3xl flex pt-[6rem] mr-auto ml-auto inset-0"}>

            <div>
                <div className={"mt-[2.294rem]  flex-col flex"}>
                    <TextInput text={"Change Password"}
                        className={"text-[#ffffff] font-Poppins text-3xl font-normal leading-tight"} />
                    <TextInput text={"Please enter the new password below."}
                        className={" mt-[2.25rem] text-[#999996] leading-trim font-Poppins text-base font-normal leading-normal"} />
                </div>
                <div className={"w-[23rem] h-[0.063rem] bg-[#EFEFEF] mt-[2.25rem]"} />

                <div className={"mt-[2.25rem]"}>
                    <FormBuilder
                        data={formType}
                        formData={newPasswordInputs}
                        onChange={(e) => handleChange(e)}
                        containerClassName={"flex-col flex w-full h-full "}
                        inputClassName={inputClassName}
                        isShown={error}
                        type={showPassword}
                        disableErrorMessage={true}
                        showOnClick={(e) => setShowPasswordType(e)}
                        isError={isError}
                    />
                </div>

                <div className={'mt-2 w-96'}>
                    <Error
                        message={isError}
                        isShown={isError} />
                </div>
                <div className={'mt-[0.469rem] w-96'}>
                    <div className={'flex-col flex w-full'}>
                        <label className={'font-Poppins text-[1rem] leading-[0.938rem] text-[#999996]'}>New Password
                            must contain:</label>
                        <div className="password-requirements flex">
                            <ul>
                                <li style={{
                                    width: '9.813rem',
                                    height: '0.8rem',
                                    fontSize: '14px',
                                    fontHeight: '15px',
                                    color: errRegexObj['1'] ? 'red' : '#999996'
                                }}>8-20 characters
                                </li>
                                <li style={{
                                    width: '9.813rem',
                                    height: '0.8rem',
                                    fontSize: '14px',
                                    fontHeight: '15px',
                                    color: errRegexObj['2'] ? 'red' : '#999996'
                                }}>One special character
                                </li>
                                <li style={{
                                    width: '9.813rem',
                                    height: '0.8rem',
                                    fontSize: '14px',
                                    fontHeight: '15px',
                                    color: errRegexObj['3'] ? 'red' : '#999996'
                                }}>One number
                                </li>
                            </ul>
                            <ul style={{ marginLeft: '1.375rem' }}>
                                <li style={{
                                    width: '9.813rem',
                                    height: '0.8rem',
                                    fontSize: '14px',
                                    fontHeight: '15px',
                                    color: errRegexObj['4'] ? 'red' : '#999996'
                                }}>One uppercase character
                                </li>
                                <li style={{
                                    width: '9.813rem',
                                    height: '0.8rem',
                                    fontSize: '14px',
                                    fontHeight: '15px',
                                    color: errRegexObj['5'] ? 'red' : '#999996'
                                }}>One lowercase character
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className={'mt-[1.25rem]'}>
                <button type="button"
                    onClick={() => SubmitFunc()}
                    // disabled={!LoginInputs['Email'] || !LoginInputs['Password'] || (LoginInputs['Email'] && !utils.mailValidation(LoginInputs['Email']))}
                    className="rounded-[3.75rem] bg-[#52007E] shadow-md flex w-[23rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-white
                            font-Poppins text-lg font-bold leading-[1.688rem] capitalize">
                    Login
                </button>
            </div>

            <div
                className={"mt-[0.75rem] ml-auto leading-trim text-edge-cap font-Poppins text-base leading-55 underline capitalize text-[#FFFFFF]"}
                onClick={() => navigate('/login')}>
                Back to login
            </div>


            <div className={"w-full text-left mt-2 ml-24"}>
                <Error
                    message={BackendErrors}
                    isShown={BackendErrors} />
            </div>


        </div>


    )
}

