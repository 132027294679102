import React from "react";
import { InputCustom } from "../input/InputCustom";
import { CustomPhoneInput } from "../input/CustomPhoneInput";
import { SelectInput } from "../input/SelectInput";
import { EmailInput } from "../input/EmailInput";
import { Error } from "./Error";
import { PasswordInput } from "../input/PasswordInput";
import { InputImage } from "../input/InputImage";
import { TextAreaComp } from "../input/TextAreaComp";
import { IconButton, InputAdornment } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CountrySelectInput } from "../input/CountrySelectInput";
import { DateTimeInput } from "../input/DateTimeInput";
import { MultiSelect } from "../input/MultiSelect";
import { SlideInput } from "../input/SlideInput";
import { CreateAbleSelectInput } from "../input/CreateAbleSelectInput";

export function FormBuilder(props) {

    let form = props.data ? props.data : []
    let formData = []
    let data = props.formData


    form.forEach((f) => {
        let label = f.required ? f.label + ' *' + " (required)" : f.label
        let labelComp = (f.required ?
            <div className="w-full">
                <span className={'font-Poppins text-base text-[#FFFFFF]'}>{f.label}</span>
                <span className={'font-Poppins text-base text-[#F93232]'}> *</span>
                <span className={'font-Poppins text-base text-[#75797A]'}> (required)</span>
            </div> :
            <div className="w-full">
                <span className={'font-Poppins text-base text-[#FFFFFF]'}>{f.label}</span>
            </div>)
        let placeHolder = f['placeHolder']

        let labelClassName = 'text-[#FFFFFF] font-Poppins text-lg '
        let inputClassName = props.inputClassName
        let isError = props.isError && props.isShown[f.name]

        let margin = formData.length > 2 || props.hasCardTitle ? "mt-[1.75rem]" : ""
        margin = props.marginSecondRow && formData.length > 2 ? props.marginSecondRow : margin

        let marginFirstRow = props.marginFirstRow && formData.length <= 2 ? props.marginFirstRow : "mt-[1.75rem]"
        let bgError = props.isShown[f.name] ? " border-2 border-rose-600" : "bg-[#FFFFFF]"
        bgError = f.disabled ? 'bg-white bg-transparent' : bgError
        f['disabled'] = props.disabled

        if (f.type === 'newLine') {
            formData.push(
                <div className={"mt-[5.125rem] w-[94.5rem] h-[0.063rem] bg-[#E0E0D7] clear-both md:col-span-1 xl:col-span-2 2xl:col-span-3"}
                    key={f.name}>
                </div>
            )
        } else if (f.type === 'text' || f.type === 'number') {
            formData.push(

                <div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>

                    <InputCustom
                        isError={isError}
                        containerClassName={props.containerClassName}
                        inputClassName={props.inputClassName + bgError}
                        onChange={props.onChange}
                        type={f.type}
                        arrayIndex={props.arrayIndex}
                        disabled={f.disabled}
                        placeholder={placeHolder}
                        labelClassName={labelClassName}
                        labelComp={labelComp}
                        name={f.name}
                        label={label}
                        data={data}
                        value={data[f.name] && data[f.name] !== "" ? data[f.name] : ''}
                        maxLength={30}
                    />
                    <Error
                        message={f.error}
                        isShown={props.isError && props.isShown[f.name]} />
                </div>)
        } else if (f.type === 'slide') {
            formData.push(
                <div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                    <SlideInput
                        leftLabel={"Disable"}
                        name={f.name}
                        disabled={f.disabled}
                        onChange={(e) => props.onChange({ target: { name: f.name, value: e.target.value } })}
                        rightLabel={"Enable"}
                        value={data[f.name]}
                        step={1}
                        min={0}
                        max={1}
                    /></div>)
        } else if (f.type === 'textArea') {
            formData.push(
                <div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                    <TextAreaComp
                        isError={isError}
                        containerClassName={props.containerClassName}
                        textAreaInputClassName={props.textAreaInputClassName}
                        onChange={props.onChange}
                        disabled={props.disabled}
                        placeholder={placeHolder}
                        labelClassName={labelClassName}
                        labelComp={labelComp}
                        name={f.name}
                        label={label}
                        maxLength={"500"}
                        textAreaStyle={props.textAreaStyle}
                        value={data[f.name] && data[f.name] !== "" ? data[f.name] : ''}
                    />
                    <Error
                        message={f.error}
                        isShown={props.isError && props.isShown[f.name]} />
                </div>)
        } else if (f.type === 'phone') {
            formData.push(<div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                <CustomPhoneInput
                    isError={isError}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName + bgError}
                    name={f.name}
                    disabled={props.disabled}
                    onChange={val => props.onChange({ target: { name: f.name, value: val } })}
                    placeholder={placeHolder}
                    labelClassName={labelClassName}
                    labelComp={labelComp}

                    label={label}
                    value={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]} />
            </div>)
        } else if (f.type === 'select') {
            formData.push(<div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                <SelectInput
                    isError={isError}
                    bgColor={props.isShown[f.name] ? "#FFF5F5" : "#FFFFFF"}
                    borderColor={props.isShown[f.name] ? "1px solid #F93232" : "1px solid #D7DDDF"}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName + bgError}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={f.disabled}
                    onChange={val => props.onChange({ target: { name: f.name, value: val.value } })}
                    data={f.data}
                    labelComp={labelComp}
                    labelClassName={labelClassName}
                    label={label}
                    isIcon={f.isIcon}
                    svgName={f['iconName']}
                    value={data[f.name] ? data[f.name] : ''}
                    selected={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]} />
            </div>)
        } else if (f.type === 'selectCreate') {
            formData.push(<div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                <CreateAbleSelectInput
                    isError={isError}
                    bgColor={props.isShown[f.name] ? "#FFF5F5" : "#FFFFFF"}
                    borderColor={props.isShown[f.name] ? "1px solid #F93232" : "1px solid #D7DDDF"}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName + bgError}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={f.disabled}
                    onChange={val => props.onChange({ target: { name: f.name, value: val, isNew: val && val['__isNew__'] } })}
                    data={f.data}
                    labelComp={labelComp}
                    labelClassName={labelClassName}
                    label={label}
                    isIcon={f.isIcon}
                    svgName={f['iconName']}
                    value={data[f.name] ? data[f.name] : ''}
                    selected={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]} />
            </div>)
        } else if (f.type === 'MultiSelect') {

            formData.push(<div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                <MultiSelect
                    isError={isError}
                    entry={data}
                    labelsName={f.labelsName}
                    bgColor={props.isShown[f.name] ? "#FFF5F5" : "#FFFFFF"}
                    borderColor={props.isShown[f.name] ? "1px solid #F93232" : "1px solid #D7DDDF"}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName + bgError + ""}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={f.disabled}
                    onChange={val => props.onChange({ target: { name: f.name, value: val.target.value } })}
                    data={f.data}
                    labelComp={labelComp}
                    labelClassName={labelClassName}
                    label={label}
                    isIcon={f.isIcon}
                    svgName={f['iconName']}
                    value={data[f.name] ? data[f.name] : ''}
                    selected={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]} />
            </div>)
        } else if (f.type === 'email') {
            formData.push(
                <div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                    <EmailInput
                        isError={isError}
                        containerClassName={props.containerClassName}
                        inputClassName={inputClassName + bgError}
                        name={f.name}
                        disabled={props.disabled}
                        placeholder={""}
                        onChange={props.onChange}
                        data={f.data}
                        maxLength={"40"}
                        labelClassName={labelClassName}
                        labelComp={labelComp}

                        label={label}
                        value={data[f.name] ? data[f.name] : ''}
                    />
                    <Error
                        message={f.error}
                        isShown={props.isError && props.isShown[f.name]} />
                </div>)
        } else if (f.type === 'image') {
            formData.push(<div className={"mx-0.5"} key={f.name}><InputImage
                isError={isError}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName + bgError}
                name={f.name}
                disabled={props.disabled}
                placeholder={placeHolder}
                onChange={props.onChange}
                data={f.data}
                labelClassName={'font-Poppins text-lg '}
                labelComp={labelComp}

                label={data[f.name]}
                fileName={props.fileName}
                previewImage={props.previewImage}
                value={data[f.name]}
                deletePhoto={props.deletePhoto}
                removeStyle={props.removeStyle}
            />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]} />
            </div>)
        } /*else if (f.type === 'number') {
            formData.push(<div className={"mt-2"} key={f.name}>
                <InputNumber
                isError={isError}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName + bgError}
                name={f.name}
                placeholder={placeHolder}
                disabled={props.disabled}
                onChange={props.onChange}
                data={f.data}
                labelClassName={labelClassName}
                labelComp={labelComp}

                label={label}
                max={f.max}
                min={f.min}
                value={data[f.name] ? data[f.name] : ''}
            />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]}/>
            </div>)
        }*/
        else if (f.type === 'password') {
            formData.push(
                <div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                    <PasswordInput
                        isError={isError}
                        containerClassName={props.containerClassName}
                        inputClassName={inputClassName + bgError}
                        name={f.name}
                        placeholder={placeHolder}
                        disabled={props.disabled}
                        onChange={props.onChange}
                        data={f.data}
                        labelClassName={labelClassName}
                        labelComp={labelComp}

                        label={label}
                        maxLength={"40"}
                        type={props.type}
                        value={data[f.name] ? data[f.name] : ''}
                        showOnClick={props.showOnClick}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => props.showOnClick()}
                                >
                                    {props.showPassword ? <Visibility style={{ color: '#52007E' }} /> :
                                        <VisibilityOff style={{ color: '#52007E' }} />}
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                    {!props.disableErrorMessage && <Error
                        message={f.error}
                        isShown={props.isError && props.isShown[f.name]} />}

                </div>)
        } else if (f.type === 'password2') {
            formData.push(
                <div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}>
                    <PasswordInput
                        containerClassName={props.containerClassName}
                        inputClassName={inputClassName + bgError}
                        name={f.name}
                        placeholder={placeHolder}
                        disabled={props.disabled}
                        onChange={props.onChange}
                        data={f.data}
                        labelClassName={labelClassName}
                        labelComp={labelComp}

                        label={label}
                        maxLength={"40"}
                        showPassword={props.type[f.name]}
                        type={props.type[f.name] ? 'text' : 'password'}
                        value={data[f.name] ? data[f.name] : ''}
                        showOnClick={async () => await props.showOnClick(f.name)}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={async () => await props.showOnClick(f.name)}
                                >
                                    {props.showPassword ? <Visibility style={{ color: '#52007E' }} /> :
                                        <VisibilityOff style={{ color: '#52007E' }} />}
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                    <Error
                        message={f.error}
                        isShown={props.isError && props.isShown[f.name]} />
                </div>)
        } else if (f.type === 'dateTime') {
            if (f.name === "End_date") {
                f.disabled = false
            }
            formData.push(<div className={margin + " max-w-[23rem] " + marginFirstRow} key={f.name}><DateTimeInput
                isError={isError}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName + bgError}
                bgColor={props.isShown[f.name] ? "#FFF5F5" : "#FFFFFF"}
                name={f.name}
                placeholder={placeHolder}
                disabled={f.disabled}
                onChange={val => props.onChange({ target: { name: f.name, value: val } })}
                data={f.data}
                Start_date={data.Start_date}
                End_date={data.End_date}
                labelClassName={labelClassName}
                label={label}
                labelComp={labelComp}
                isIcon={f.isIcon}
                value={data[f.name] ? data[f.name] : ''}
                selected={data[f.name] ? data[f.name] : ''}
            />
                <Error
                    message={f.error}
                    isShown={props.isError && props.isShown[f.name]} />
            </div>)

        }
    });


    return (

        <>
            {formData}
        </>

    )
}
