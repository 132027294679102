import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BodyHeader from "../../components/customComp/BodyHeader";
import { TableRowOptions } from "../../components/table/TableRowOptions";
import SVGraphics from "../../assets/SVGraphics";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import { Table } from "../../components/table/table";

export function UserComps(props) {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([{}]);

    useEffect(() => {
        fetchData({}).then(p => {
            setUsers(p.data)
            setFilteredData(p.data)
        })
    }, [])

    const fetchData = async () => {
        return await props.restApi.getAllUsers()
    }



    const columns = [
        {
            Header: 'First Name',
            accessor: 'FirstName',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Users/EditUser/${cell.row.original['UserIndex']}`)}>
                    {cell.row.original?.FirstName}
                </div>)
            }
        },
        {
            Header: 'Last Name',
            accessor: 'LastName',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Users/EditUser/${cell.row.original['UserIndex']}`)}>
                    {cell.row.original?.LastName}
                </div>)
            }
        },
        {
            Header: 'Email',
            accessor: 'Email',
            headerClassName: 'w-[20.25rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Users/EditUser/${cell.row.original['UserIndex']}`)}>
                    {cell.row.original?.Email}
                </div>)
            }
        },

        {
            Header: 'Role',
            accessor: 'Role',
            headerClassName: 'w-[8.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Users/EditUser/${cell.row.original['UserIndex']}`)}>
                    {cell.row.original?.Role}
                </div>)
            }
        },
        {
            Header: 'Disabled',
            accessor: 'Disabled',
            headerClassName: 'w-[5.25rem] ',
            Cell: (cell) => {
                return (
                    <SVGraphics
                        fill={'none'}
                        className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                        svgname={cell.row.original['Enabled'] ? '' : 'keyLock'} />)

            }
        }
    ]

    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = users.filter(user => {

            return String(user['FirstName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['LastName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Email']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Role']).toLowerCase().indexOf(filterValue) > -1
        }
        )
        setFilteredData(filteredData)
    }

    return (
        <div className={'w-full px-[3.75rem] py-[3rem]'}>

            <BodyHeader title={"Users Management"} includeButton={true} buttonText={" Add User"}
                includePlus={true} readOnly={false}
                OnButtonClick={() => navigate(`/${props.rolePath}/Users/AddUser`)} />

            <div
                className="  h-fit  font-Poppins rounded-lg ">

                <BodySearchFilter
                    onSearchChange={(searchText) => onSearchChange(searchText)}
                    hasSearch={true}
                    hasFilter={false}
                    hasDownload={false}
                    hasClearInRow={false}
                />

                <div className={"mt-[1.5rem]"}>
                    <Table data={filteredData} columns={columns} isLoading={false} />
                </div>


            </div>

        </div>
    )
}