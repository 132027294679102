import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "../input/TextInput";
import SVGraphics from "../../assets/SVGraphics";
import { useNavigate } from "react-router-dom";
import { authenticationService } from "../../services/AuthinticationService";
import AppLogout from "../../views/auth/AppLogout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";



export function SideNavigationBar(props) {
    const {
        logoutUser
    } = props
    const [navType, setNavType] = useState('Dashboard');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate()
    const user = sessionStorage.getItem('loggedInUser')

    useEffect(() => {

        authenticationService.currentUser.subscribe(
            currentUser => setCurrentUser(currentUser)
        );
        const pathname = window.location.pathname
        setNavHandler(pathname)

    }, [])

    window.addEventListener('popstate', function (event) {
        const pathname = window.location.pathname
        setNavHandler(pathname)
    });

    const setNavHandler = (pathname) => {
        if (pathname.includes("Dashboard")) {
            setNavType("Dashboard")
        } else if (pathname.includes("Users")) {
            setNavType("Users")
        }
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const types = props.NavTypes

    const setType = (type) => {
        switch (type) {
            case 'Dashboard':
                setNavType(type)
                navigate(`/${props.rolePath}/Dashboard`, { state: { NavType: 1 } })
                break;
            case 'Users':
                setNavType(type)
                navigate(`/${props.rolePath}/Users`, { state: { NavType: 2 } })
                break;
            default:
                setNavType(type)
                navigate(`/${props.rolePath}/Dashboard`, { state: { NavType: 1 } })
                break;
        }
    }

    return (
        <AppLogout>

            <div
                className="from-[#000000] to-[#1A092C] px-[1.5rem] shadow-lg min-w-[15.688rem] h-fit">

                <div className={"hidden md:block md:w-auto"} id="navbar-default">
                    <ul className="flex flex-col pt-1 min-h-[668px]">
                        {types.map((type, index) => {
                            let navTypeSelected = navType === type.name
                            let textColor = navTypeSelected ? "text-[##7A4794] font-bold" : "text-[#999996]"
                            return <li
                                key={type.name}
                                className={`${index === 0 ? "mt-[4rem]" : "mt-[4.5rem]"}`}
                                onClick={() => setType(type.name)}>
                                <div
                                    className={` cursor-pointer relative max-w-[15.25rem] h-[2.5rem] rounded flex items-center text-lg ${navTypeSelected ? "text-[#FFFFFF] bg-[#7A4794] " : "text-[#FEFEFE]"}`}>
                                    <SVGraphics
                                        className="ml-[1rem] cursor-pointer mr-[0.75rem] h-[1.5rem] m-w-[1.375rem] w-[1.375rem]"
                                        svgname={navTypeSelected ? type.selectedSvg : type.svg} fill="none" />
                                    <TextInput text={type.label}
                                        className={"w-[8rem] cursor-pointer text-dark-grey text-cap font-Poppins-bold text-base leading-[1.5rem] " + textColor} />
                                </div>
                            </li>
                        })}
                    </ul>

                </div>

                <div className={'flex items-center mt-[10rem] mb-[2rem]'} onClick={() => logoutUser()}>
                    <SVGraphics className="cursor-pointer mr-[0.75rem] h-[1.5rem] m-w-[1.375rem] w-[1.375rem]" svgname={'logout'} />
                    <TextInput text={'Logout'}
                        className="cursor-pointer text-[#ffffff] leading-trim-both text-edge-cap font-Poppins text-base font-light leading-7" />


                    {/* <SVGraphics svgname={'aboutIcon'} className={"ml-auto mr-[2rem] w-[1.5rem] h-[1.5rem]"}  onClick={handleMenu}/> */}

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Privacy Policy</MenuItem>
                    </Menu>



                </div>



            </div>

        </AppLogout>
    )
}
