// const crypto = require('crypto');
import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_CHECKSUM_SECRET;
// Define a secret key for HMAC


// Middleware to attach a hash to every response
export function bodyToHash(body) {

    const hash = CryptoJS.HmacSHA256(body, secretKey).toString();
    return hash
};

export function calcHash(originalHash, originalBody) {
    const hash = CryptoJS.HmacSHA256(originalBody, secretKey).toString();
    return hash === originalHash
};
