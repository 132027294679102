import React, { useState, useRef, useEffect } from "react";
import { authenticationService } from "../../services/AuthinticationService";
import formData from "../../components/forms/formsData.json";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import utils from "../../utils/utils";
import { Error } from "../../components/forms/Error";
import { FormBuilder } from "../../components/forms/FormBuilder";
import { TextInput } from "../../components/input/TextInput";
import AlertConfirm from "../../components/modals/Confirm";
import SVGraphics from "../../assets/SVGraphics";
import { ReCAPTCHA } from "react-google-recaptcha";
import { InputCustom } from "../../components/input/InputCustom";
// import ReCAPTCHA from "react-google-recaptcha";

export function VerificationCode() {
    const location = useLocation();
    const { state } = location;
    const [LoginInputs, setLoginInputs] = useState({
        code: ""
    });
    const [code, setCode] = useState("")
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [isError, setIsError] = useState(false);
    const [formType, setFormType] = useState(formData['mfa'])
    const [BackendErrors, setBackendErrors] = useState(null);
    const [FormErrors, setFormErrors] = useState(false);

    const inputClassName = "bg-white rounded-lg h-[3rem] w-[23rem] bg-transparent font-Poppins text-lg shadow-lg "

    async function SubmitFunc() {
        const { data } = await authenticationService.submit2fa(code, state.value)
        if (!data) {
            setFormErrors(true)
            return
        }
        let role = data.role
        const route = role ? role : ""
        navigate(`/${route}/Dashboard`);
    }

    function handleChange(e) {
        setFormErrors(false)
        const input = e.target.value
        if (input.length > 8) return

        setCode(input)
    }


    return (

        <div
            className={"flex-col font-Poppins w-[19.2%] rounded-3xl flex pt-[8rem] mr-auto ml-auto inset-0"}>

            <div>
                <div className={"mt-[2.813rem] flex-col flex"}>
                    <TextInput text={"Login Account"}
                        className={"text-[#FFFFFF] font-Poppins text-[26px] font-bold leading-tight"} />
                    <TextInput text={"Please enter your details"}
                        className={" mt-[2.25rem] text-[#999996] leading-trim font-Poppins text-base font-normal leading-normal"} />
                </div>
                <div className={"w-[23rem] h-[1px] bg-[#EFEFEF] mt-[2.25rem]"} />
                <div className={"mt-[2.25rem]"}>

                    <InputCustom
                        isError={false}
                        containerClassName={"flex-col flex w-full h-full mt-[1.938rem]"}
                        inputClassName={inputClassName}
                        onChange={handleChange}
                        type={"text"}
                        // arrayIndex={arrayIndex}
                        disabled={false}
                        placeholder={"enter code"}
                        labelClassName="text-[#FFFFFF] leading-trim font-Poppins text-lg font-normal leading-7"
                        // labelComp={labelComp}
                        name={"code"}
                        label={"Code"}
                        // data={data}
                        value={code}
                    />
                    {FormErrors && <p style={{ color: "red", position: "absolute" }}>wrong code</p>}

                </div>

                <div className={"w-full text-left mt-2 text-lg"}>
                    <Error
                        message={BackendErrors}
                        isShown={BackendErrors} />
                </div>
            </div>

            <div className={'mt-[3.25rem]'}>
                <button type="button"
                    onClick={() => SubmitFunc()}
                    disabled={!code}
                    className="rounded-full bg-[#52007E] shadow-md flex w-[23rem] h-[3rem] justify-center items-center space-x-3 flex-shrink-0 text-white
                            font-Poppins text-lg font-bold leading-7 capitalize cursor-pointer">
                    Login
                </button>
            </div>




        </div>


    )
}
