import React, { useEffect, useState } from "react";
import SVGraphics from "../../../assets/SVGraphics";
import utils from "../../../utils/utils";
import ReactApexChart from 'react-apexcharts';


function createLabelsArray(start, end, n) {
    if (n < 2) return [start, end]
    const arr = Array(n - 2).fill("")
    arr.push(end)
    arr.unshift(start)
    return arr
}

export function SessionsChart(props) {
    const state = {
        series: [{
            name: 'series1',
            data: props.session || []
        },],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                labels: {
                    style: {
                        colors: 'white'
                    }
                },
                type: 'string',
                categories: createLabelsArray(props.from, props.to, props.session ? props.session.length : 0)
            },
            yaxis: {
                labels: {
                    style: {
                        colors: 'white'
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },


    };

    return (
        <div className="mt-[1.5rem]">
            {props.session && <ReactApexChart options={state.options} series={state.series} type="area" height={350} />}

        </div>
    )
}