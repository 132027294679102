import React, { useEffect, useState } from "react";

export function HeaderInfo(props) {


    useEffect(() => {

    }, []);

    const entry = props.entry ? props.entry : {}
    const patient = entry['patient'] ? entry['patient'] : {}
    const patientSessions = entry['patientSessions'] ? entry['patientSessions'] : []
    const labelClass = "w-full mb-[0.5rem] font-Poppins-bold font-bold "
    return (
        <div className={"w-full bg-[#191E29] rounded text-white"}>
            <div
                className={`p-[1rem] w-full grid grid-cols-3 gap-x-[1.5rem] gap-y-[1.5rem] lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 sm:grid-cols-1 max-sm:grid-cols-2`}>

                <div>
                    <div className={labelClass}> Serial Number:</div>
                    <label> {entry['watchId']} </label>
                </div>
                <div>
                    <div className={labelClass}> Mail of user:</div>
                    <label>{patient['email']} </label>
                </div>
                <div>
                    <div className={labelClass}> Gender:</div>
                    <label> {patient['gender']} </label>
                </div>
                <div>
                    <div className={labelClass}> Phone type:</div>
                    <label> {patient['phoneType']} </label>
                </div>
                <div>
                    <div className={labelClass}> Age:</div>
                    <label> {patient['age']} </label>
                </div>
                <div>
                    <div className={labelClass}>Height:</div>
                    <label> {patient['height'] + ` ${`${patient['height']}`.includes("'") ? "" : patient['heightUnit']}`} </label>
                </div>
                <div>
                    <div className={labelClass}>Weight:</div>
                    <label> {patient['weight'] + ` ${patient['weightUnit']}`} </label>
                </div>
                <div>
                    <div className={labelClass}> Watch wearing hand:</div>
                    <label> {patient['watchWearingHand']} </label>
                </div>
                <div>
                    <div className={labelClass}> Number of total sessions:</div>
                    <label> {patientSessions.length} </label>
                </div>

            </div>


        </div>

    )
}