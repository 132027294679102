import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BodyHeader from "../../../components/customComp/BodyHeader";
import { HeaderInfo } from "./HeaderInfo";
import { Table } from "../../../components/table/table";
import { SessionsChart } from "./SessionChart";
import RoundedButtons, { filtersNames } from "../../../components/statistics/Filters";
import SVGraphics from "../../../assets/SVGraphics";
import { generateExcelAndDownload } from "../../../services/functions/createXlsx";
import { LoadingComponent } from "../../../components/input/LoadingComponent";
// import Filters from "../../../components/statistics/Filters";


function createGraphData(data) {
    if (!data) return
    const obj = {
        sleep: [],
        vibration: [],
        percentageOfSnoring: [],
        breathingPauses: [],
        movements: [],
        hartRate: [],
        snoring: [],
        oxygenSaturation: []
    }
    data.map(val => {
        obj.sleep.push(val.sleep)
        obj.vibration.push(val.vibration)
        obj.percentageOfSnoring.push(val.percentageOfSnoring)
        obj.breathingPauses.push(val.breathingPauses)
        obj.movements.push(val.movements)
        obj.hartRate.push(val.hartRate.hartRateAverage)
        obj.snoring.push(val.snoring.snoringAverage)
        obj.oxygenSaturation.push(val.oxygenSaturation.oxygenSaturationAverage)
    })
    return obj

}



function getFormattedDate(daysAgo = 0) {
    const today = new Date();
    const targetDate = new Date(today.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    const year = targetDate.getFullYear();
    let month = targetDate.getMonth() + 1;
    let day = targetDate.getDate();

    // Add leading zeros if month or day is a single digit
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }

    return `${year}-${month}-${day}`;
}



export function DeviceStatisticsInfo(props) {
    const params = useParams();
    const [popupType, setPopupType] = useState()
    const [datePicker, setDatePiker] = useState("week")
    const [selectedRange, setSelectedRange] = useState({});
    const [statisticsDataFromServer, setStatisticsDataFromServer] = useState()
    const [statisticsData, setStatisticsData] = useState()
    const [isLoading, setIsLoading] = useState(false);



    const [filter, setFilter] = useState("sleep")
    const [filteredArray, setFilteredArray] = useState([])
    const [graphData, setGraphData] = useState([])

    const [from, setFrom] = useState(getFormattedDate(7))
    const [to, setTo] = useState(getFormattedDate())

    useEffect(() => {
        if (graphData) {
            setFilteredArray(graphData[filter])
        }
    }, [filter, graphData])


    useEffect(() => {
        getStatistics()
    }, [to, from])


    async function getStatistics() {
        setIsLoading(true)
        if (from && to) {
            const { data } = await props.restApi.getSessionsStatistics({
                startDate: from,
                endDate: to,
                patientIndex: props.sessions[0].patientIndex
            })
            setStatisticsDataFromServer(data.patientSessions)
            setGraphData(createGraphData(data.patientSessions))
        }
        setIsLoading(false)
    }

    return (
        <div className="h-fit  font-Poppins rounded-lg ">
            <div
                onClick={async () => { await generateExcelAndDownload(statisticsDataFromServer, `${statisticsDataFromServer[0].watchIndex || ""}-statistics.xlsx`) }}
                className={"flex justify-center cursor-pointer ml-auto w-[17rem] h-[3rem] py-[0.75rem] px-[1.5rem] text-center text-lg font-bold font-Poppins flex-shrink-0 rounded-full bg-[#52007E] shadow-black " +
                    "text-[#FFF]"}>
                {props.includePlus &&
                    <SVGraphics className={'mt-[0.3rem] w-[1rem] h-[1rem] cursor-pointer flex-shrink-0'} fill="#FEFEFE"
                        svgname={'plusIcon'} />
                }
                <label className={"ml-[12px] cursor-pointer text-center text-lg font-bold font-Poppins" +
                    "text-[#FFF]"}>export session data</label>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "45%",
                justifyContent: "space-between",
            }}>
                <div style={{ flex: 1 }}>
                    <div
                        className={" cursor-pointer h-[2.75rem] text-[#FFFFFF] text-cap font-Poppins text-[1.5rem] leading[3.75rem] font-bold leading-60 mr-[3rem]"}>
                        from
                    </div>
                    <input
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        type="date"
                        title="from"
                        style={{ backgroundColor: "#52007e", width: "80%", }} />
                </div>
                <div style={{ flex: 1 }}>
                    <div
                        className={" cursor-pointer h-[2.75rem] text-[#FFFFFF] text-cap font-Poppins text-[1.5rem] leading[3.75rem] font-bold leading-60 mr-[3rem]"}>
                        to
                    </div>
                    <input
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        type="date"
                        title="to"
                        style={{ backgroundColor: "#52007e", width: "80%", }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", flex: 1, }}>
                    <div
                        className={" cursor-pointer h-[2.75rem] text-[#FFFFFF] text-cap font-Poppins text-[1.5rem] leading[3.75rem] font-bold leading-60 mr-[3rem]"}>
                        filter
                    </div>
                    <select value={filter} onChange={val => setFilter(val.target.value)} style={{ backgroundColor: "#52007e", width: "80%", height: "1.8rem", }}>
                        {filtersNames.map((val, index) =>
                            <option value={val.value} kay={"afff" + index}>{val.name}</option>
                        )}
                    </select>
                </div>
            </div>
            <SessionsChart from={from} to={to} session={filteredArray} />
            {isLoading && <LoadingComponent />}



        </div>
    )
}