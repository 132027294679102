import React from "react";

const TablePagination = (props) => {
    return (
        <div className="w-full flex justify-center pr-1 mt-[2rem]">
            <div className=" flex">

                {props.pageIndex !==0 && <button
                    onClick={props.previousPage}
                    disabled={!props.canPreviousPage}
                    className={"pr-2 text-[#FFFFFF]"}
                > ❮
                </button>}
                <div className={"text-[#FFFFFF]"}>{props.pageIndex + 1}  of {props.pageOptions.length}</div>

                { props.pageIndex + 1 !== props.pageOptions.length &&<button
                    onClick={
                        props.nextPage
                    }
                    disabled={!props.canNextPage}
                    className={"pl-2 text-[#FFFFFF]"}
                >❯
                </button>}
            </div>
        </div>
    );
}

export default TablePagination;