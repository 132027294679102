import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SVGraphics from "../../assets/SVGraphics";

export function DateTimeInput(props) {


    const CustomInput = ({ onClick, value, placeholder,onChange }) => {
        return (
            <div className="relative">
                <input
                    className={`${props.inputClassName} pl-[1rem] border border-[#E9E9EB] placeholder:font-normal opacity-100 placeholder:opacity-100`}
                    onClick={onClick}
                    onChange={onChange}
                    value={value?value:''}
                    placeholder={placeholder}
                />
                <div className="absolute top-0 right-0 bottom-0 px-3 flex items-center pointer-events-none">
                    <SVGraphics className={"w-[1rem] h-[1rem]"} svgname={'calendar'}/>

                </div>
            </div>
        );
    };
    return (

        <div className={props.containerClassName}>
            {props.labelComp}

            {props.hasRange && <DatePicker
                calendarClassName={'vertica-date-time'}
                // selected={props.selected}
                id={props.name + props.value}
                disabled={props.disabled}
                key={props.name}
                startDate={props.selected[0]}
                endDate={props.selected[1]}
                selectsRange={props.hasRange}
                onChange={props.onChange}
                placeholderText={props.placeholder}
                minDate={props.minDate}
                customInput={<CustomInput
                    placeholder={props.placeholder}/>}

            />}
            {!props.hasRange && <DatePicker
                calendarClassName={'vertica-date-time'}
                selected={props.selected}
                id={props.name + props.value}
                key={props.name}
                disabled={props.disabled}
                selectsRange={props.hasRange}
                onChange={props.onChange}
                placeholderText={props.placeholder}
                minDate={props.minDate}
                customInput={<CustomInput
                    placeholder={props.placeholder}/>}

            />}
        </div>
    )
}
