import React, { useState } from "react";
import { Table } from "../../../components/table/table";
import { LoadingComponent } from "../../../components/input/LoadingComponent";

export function DeviceSessionsInfo(props) {
    const [isLoading, setIsLoading] = useState(false);

    async function downloadRecording(recordingPath) {
        if (!recordingPath) return
        setIsLoading(true)
        const res = await props.restApi.getRecording(recordingPath)
        const name = recordingPath.split("/").at(-1)
        downloadFileFromS3(res.data.recordingUrl, name)
        setIsLoading(false)

    }

    function downloadFileFromS3(presignedUrl, fileName) {
        // Create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();

        // Set the response type to blob
        xhr.responseType = 'blob';

        // Open a GET request to the pre-signed URL
        xhr.open('GET', presignedUrl);

        // Set onload callback function
        xhr.onload = function () {
            // Check if request was successful
            if (xhr.status === 200) {
                // Create a new anchor element
                var a = document.createElement('a');
                // Set the href attribute to the blob URL
                a.href = window.URL.createObjectURL(xhr.response);
                // Set the download attribute to the desired filename
                a.download = fileName;
                // Append the anchor element to the body
                document.body.appendChild(a);
                // Click the anchor element to initiate download
                a.click();
                // Remove the anchor element from the body
                document.body.removeChild(a);
            } else {
                // If request was not successful, log the error
                console.error('Failed to download file. Status code: ' + xhr.status);
            }
        };

        // Set onerror callback function
        xhr.onerror = function () {
            console.error('Failed to make request to the server.');
        };

        // Send the request
        xhr.send();
    }


    function getTimeDifference(fromTime, toTime) {
        const from = new Date(fromTime);
        const to = new Date(toTime);

        const timeDifference = to - from;

        // Convert milliseconds to hours and minutes
        const hours = Math.floor(timeDifference / 1000 / 60 / 60);
        const minutes = Math.round((timeDifference / 1000 / 60) % 60);

        // Format the result
        const formattedDifference = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${(!minutes && !hours ? 1 : minutes)}`;//return the time in hh:mm format. if the time is 00:00 display 00:01

        return formattedDifference;
    }
    function formatDateToDDMMMMYYYYHHMM(date) {
        // Create a new Date object from the input date string
        let dt = new Date(date);

        // Extract the parts
        let day = dt.getDate().toString().padStart(2, '0');
        let month = dt.toLocaleString('en-US', { month: 'long' }); // Gets the full month name
        let year = dt.getFullYear();

        let hours = dt.getHours().toString().padStart(2, '0');
        let minutes = dt.getMinutes().toString().padStart(2, '0');

        // Combine them into the desired format
        return `${day} ${month} ${year} ${hours}:${minutes}`;
    }


    const columns = [
        {
            Header: 'Session date',
            accessor: 'session date',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                let value = cell.row.original?.['sessionStartDate']
                // value = value?value.toLocaleString():""
                const formattedDate = new Date(value).toLocaleString([], { hour12: false });
                return (<div className={"cursor-pointer"}
                    onClick={() => props.changeView(2, cell.row.original['patientSessionIndex'])}>
                    {formatDateToDDMMMMYYYYHHMM(value)}
                    {/* {value} */}
                </div>)
            }
        },
        {
            Header: 'Sleep time',
            accessor: 'watchId',
            headerClassName: 'w-[13rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer text-white "}
                    onClick={() => props.changeView(2, cell.row.original['patientSessionIndex'])}>
                    {getTimeDifference(cell.row.original?.['sessionStartDate'], cell.row.original?.['sessionEndDate'])}
                </div>)
            }
        },
        {
            Header: 'Snoring percentage',
            accessor: 'email',
            headerClassName: 'w-[18rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => props.changeView(2, cell.row.original['patientSessionIndex'])}>
                    {Math.floor(cell.row.original?.['percentageOfSnoring'])}%

                </div>)
            }
        },
        {
            Header: 'Snoring noise average',
            accessor: 'DateOfFirstSession ',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => props.changeView(2, cell.row.original['patientSessionIndex'])}>
                    {Math.floor(cell.row.original?.['snoring']["snoringAverage"])}
                </div>)
            }
        },
        {
            Header: 'Number of vibrations',
            accessor: 'DateOfLastSession ',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {

                return (<div className={"cursor-pointer"}
                    onClick={() => props.changeView(2, cell.row.original['patientSessionIndex'])}>
                    {cell.row.original?.['vibration']}
                </div>)
            }
        },
        {
            Header: 'Download recording',
            accessor: 'download ',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {

                return (<div className={"cursor-pointer"}
                    onClick={() => downloadRecording(cell.row.original?.['recordingS3Path'])}>
                    {"Download"}
                </div>)
            }
        },
    ]
    return (
        <div className={"mt-[1.5rem]"}>
            {isLoading && <LoadingComponent />}

            <Table data={props.sessions} columns={columns} isLoading={false} />
        </div>
    )
}