import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BodyHeader from "../../components/customComp/BodyHeader";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import { Table } from "../../components/table/table";
import { generateExcelAndDownload } from "../../services/functions/createXlsx";
import { LoadingComponent } from "../../components/input/LoadingComponent";

export function MainDashboard(props) {

    const [entry, setEntry] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true)
        fetchData().then(response => {
            // console.log("response", response)
            setEntry([...response.data])
            setFilteredData([...response.data])
            setIsLoading(false)

        })

    }, []);

    const fetchData = async () => {
        return await props.restApi.getDeviceData()
    }


    const columns = [
        {
            Header: 'Serial Number',
            accessor: 'watchId',
            headerClassName: 'w-[13rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Device/${cell.row.original.patientWatch['watchIndex']}`)}>
                    {cell.row.original?.patientWatch.watchId}
                </div>)
            }
        },
        {
            Header: 'Email',
            accessor: 'email',
            headerClassName: 'w-[18rem] ',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Device/${cell.row.original.patientWatch['watchIndex']}`)}>
                    {cell.row.original?.email}
                </div>)
            }
        },
        {
            Header: 'Date of first session ',
            accessor: 'DateOfFirstSession ',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                let patientSessions = cell.row.original?.patientSessions
                let value = patientSessions && patientSessions.length > 0 ? (patientSessions[0]['sessionStartDate']).split('T')[0] : ''
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Device/${cell.row.original.patientWatch['watchIndex']}`)}>
                    {value}
                </div>)
            }
        },
        {
            Header: 'Date of last session ',
            accessor: 'DateOfLastSession ',
            headerClassName: 'w-[12.625rem] ',
            Cell: (cell) => {
                let patientSessions = cell.row.original?.patientSessions
                let value = patientSessions && patientSessions.length > 0 ? (patientSessions[patientSessions.length - 1]['sessionStartDate']).split('T')[0] : ''
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Device/${cell.row.original.patientWatch['watchIndex']}`)}>
                    {value}
                </div>)
            }
        },
        {
            Header: 'Number of total sessions',
            accessor: 'NumberOfTotalSessions',
            headerClassName: 'w-[15rem] ',
            Cell: (cell) => {
                let patientSessions = cell.row.original?.patientSessions
                let value = patientSessions.length > 0 ? patientSessions.length : 0
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/Device/${cell.row.original.patientWatch['watchIndex']}`)}>
                    {value}
                </div>)
            }
        }
    ]

    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = entry.filter(item => {
            if (String(item['patientWatch']['watchId']).toLowerCase().indexOf(filterValue) > -1
                ||
                String(item['email']).toLowerCase().indexOf(filterValue) > -1)
                return true
            return false
        }
        )
        setFilteredData(filteredData)
    }




    async function exportCSV() {
        const { data } = await props.restApi.get_watch_xlsx()
        const ws = await generateExcelAndDownload(data, "devices.xlsx")
    }

    return (
        <div className={'w-full px-[3.75rem] py-[3rem]'}>
            <BodyHeader includeButton OnButtonClick={exportCSV} buttonText={"Export to csv"} title={"Dashboard"} />

            <BodySearchFilter
                onSearchChange={(searchText) => onSearchChange(searchText)}
                hasSearch={true}
                hasFilter={false}
                hasDownload={false}
                hasClearInRow={false}
            />

            <div className={"mt-[1.5rem]"}>
                <Table data={filteredData} columns={columns} isLoading={false} />
            </div>

            <div className={"w-[8%] ml-auto mt-[1.5rem] text-[#FFFFFF] font-Poppins font-semibold"}>
                Total: {filteredData.length} devices
            </div>

            {isLoading && <LoadingComponent />}

        </div>
    )
}