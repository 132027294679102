import React from "react";



export function InputCustom(props) {
    const hasLabel = props.labelComp?props.labelComp:<div>
        <span className={'font-Poppins text-base text-[#FFFFFF]'}>{props.label}</span>
    </div>


    let key = props.arrayIndex || props.arrayIndex===0?"Input"+props.name+props.arrayIndex:"Input"+props.name
    return (
        <div className={props.containerClassName}>
            {hasLabel}
          {/*  <input
                className={props.inputClassName + borderColor+` border border-solid pl-[0.75rem] rounded-lg` }
                disabled={props.disabled}
                type={props.type}
                maxLength={props.maxLength}
                value={props.data[props.name+"test"]?props.data[props.name+"test"]:''}
                onChange={props.onChange}
                name={props.name+"test"}
                placeholder={"test"}
            />*/}
            <input
                key={key}
                className={props.inputClassName + ` border border-solid border-[#E9E9EB] pl-[0.75rem] rounded-lg focus:outline-none ` }
                disabled={props.disabled}
                type={props.type}
                maxLength={props.maxLength}
                // error={isErr}
                value={props.value}
                onChange={props.onChange}
                // helperText={isErr ? errMessage : ''}
                placeholder={props.placeholder}
                // required={required}
                name={props.name}
            />

        </div>
    )
}
