import { authenticationService } from "../AuthinticationService";
import restApi from "./RestApi";

const getCurrentUser = () => {
    return authenticationService.currentUserValue;
}

const SuperAdminApiService = {
    addUser: async (entry) => {

        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/users/addUser`,
            entry
        );
    },

    getAllUsers: async () => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/users/getAll`,
        );
    },


    editUser: async (entry) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/users/${entry['UserIndex']}`,
            entry
        );
    },


    getUserByIndex: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/users/${index}`
        );
    },

    getAllDeviceSessions: async (index) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/${index}/sessions`
        );
    },

    getDeviceSession: async (index, sessionIndex) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/${index}/session/${sessionIndex}`
        );
    },

    getDeviceData: async (filters) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/dashboard/device_data`,
            filters
        );
    },

    get_watch_xlsx: async (filters) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/dashboard/get_watch_xlsx`,
            filters
        );
    },

    getSessionsStatistics: async (body) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/dashboard/getSessionsStatistics`,
            body
        );
    },

    getDeviceSessionData: async (sessionIndex) => {
        return await restApi.get(
            `admin/${getCurrentUser().profile['UserIndex']}/device/getFullSessionDataBySessionId/${sessionIndex}`
        );
    },

    getRecording: async (recordingPath) => {
        return await restApi.post(
            `admin/${getCurrentUser().profile['UserIndex']}/device/getRecording`,
            { recordingPath }
        );
    },



};

export default SuperAdminApiService
