import React from 'react';
import "../../style/input/loadingComponent.css"


export function LoadingComponent() {

    return (
        <div className="">
            <div className={"loader"} />
        </div>
    );

}

